<nav class="navbar fixed-top navbar-expand-sm  navbar-white" style="z-index: 999">


  <a class="navbar-log ml-3">
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>



  <button class="navbar-toggler but mr-3 " type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
    aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarTogglerDemo02">
    <span class="navbar-toggler-icon "></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed" style="background: white">
    <ul class="navbar-nav ml-auto ">
      <li class="nav-item data1">
        <!-- <a routerLink="/displayjobs"  class="nav-link pl-3 pr-3">
          <i class="fa fa-dashboard"></i>
          Jobs
        </a> -->
      <a  class="nav-link pl-4 pr-3 mt-0" style="font-size: 16px;
      font-weight: 400;"> <i class="simple-icon-user"> </i>  {{user_list[0].user_fullname}}</a>
      </li>
      <li class="nav-item data1">
        <a (click)="onSignOut()"  style="text-align:center ;" class="nav-link pl-3 pr-3">
          <i class="fa fa-cogs"></i>
          Logout
        </a>
      </li>
    </ul>
  </div>

  <!-- <div class="ml-auto">
    <a class="navbar-logo">
      <span class="logo d-none d-xs-block"></span>
      <span class="logo-mobile d-block d-xs-none"></span>
    </a>
  </div>
  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">
      <button mat-raised-button color="primary" style="padding: 10px;
      color: white;
      width: 100px;
      border-radius: 25px;
      background-color: #19a7e1 !important; border-color: #19a7e1;
      margin-right: 10px;" routerLink="/displayjobs">
        Jobs
      </button>
      <button mat-raised-button color="primary" style="padding: 10px;
      color: white;
      width: 100px;
      border-radius: 25px;
      background-color: #19a7e1 !important; border-color: #19a7e1;
      margin-right: 10px;" routerLink="/">
        Logout
      </button>
    </div>
  </div>
  <div class="navbar-right" style="display: none;">
    <div class="header-icons d-inline-block align-middle">



      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span>
          <img alt="Profile Picture" src="/assets/img/profiles/l-1.jpg" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer">My Profile</a>
        >
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
      </div>
    </div>

  </div> -->
</nav>
